.PaperTable {
  margin-top: 16px;

  &__id {
    font-size: 14px;
  }
  
  &__title {
    font-size: 18px;
  }
}