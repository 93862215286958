$color: black;

.OCTable {
  text-align: justify;

  tr {
    border-bottom: 1px solid #ddd;
  }

  &__position {
    font-size: 20px;
    color: $color;
  }

  &__person {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__address {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}