.Header {
  font-size: 25px;
  color: #fff;
  background-image: url(../../assets/bar_v2.png);
  img {
    width: auto;
    height: 64px;
  }
  span {
    position: absolute;
    padding-left: 10px;
    top: 12px;
  }
}