.SponsorPage__tableTile {
  color: white;
  border: 2px solid black;
  margin: 0px;
  padding: 10px;
  margin-top: 10px;
}
.SponsorPage__tableTile--gold {
  @extend .SponsorPage__tableTile;
  background-color: gold;
}
.SponsorPage__tableTile--silver {
  @extend .SponsorPage__tableTile;
  background-color: silver;
}
.SponsorPage__tableTile--bronze {
  @extend .SponsorPage__tableTile;
  background-color: lightgreen;
}
.SponsorPage__table {
  margin-top: 0px;
  td {
    padding: 50px;
  }
}
