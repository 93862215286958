.HomePage {
  
  &__ContainerStyle {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: lighten(rgb(224, 221, 221), 6%);
    color: black;
    border-radius: 10px;
  }
}

