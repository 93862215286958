$border-style: 2px solid #ddd;
$radius: 8px;

.nav-item {
  border: {
    top: $border-style;
    left: $border-style;
    right: $border-style;
  }   

  &:first-child {
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  } 

  &:last-child {
    border-bottom: $border-style;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  
  &:hover {
    background-color: darken(white, 10%);
  }
  a {
    color: #555;
  }
}

.active {
  background-color: darken(white, 20%);
}